<template>
  <div class="calendar__day" @click="dayClick()" :class="classList">
    {{ day.dayNumber }}
  </div>
</template>

<script>
export default {
  name: "CalendarDay",
  props: {
    day: Object,
  },
  computed: {
    classList: {
      cache: false,
      get() {
        return {
          calendar__day_weekend: this.day.isWeekend,
          "calendar__day_last-month": this.day.isLastMonth,
          "calendar__day_next-month": this.day.isNextMonth,
          calendar__day_today: this.day.isToday,
          calendar__day_current: this.day.isCurrent,
          calendar__day_hasEvents: this.day.events.length,
          calendar__day_passed: this.day.isPassed,
          calendar__day_onlyPassedEvents: this.day.onlyPassedEvents,
        };
      },
    },
  },
  methods: {
    dayClick() {
      if (this.day.events.length) {
        if (!this.day.isCurrent) {
          this.$emit("dayClick", this.day);
        } else {
          this.$emit("dayClick", false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  &__day {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #1f1f1f;
    margin: 0 auto;
    transition: 0.3s;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      width: 32px;
      height: 32px;
    }

    &_weekend {
      color: #9a3374;
    }

    &_today {
      background-color: #ebefee;
    }

    &_onlyPassedEvents {
     border: 1px solid #ebefee;
    }

    &_last-month,
    &_next-month {
      color: #bdbdbd;
    }

    &_hasEvents {
      border: 1px solid #da338c;
      cursor: pointer;

      &:hover {
        background-color: #ebefee;
      }
    }

    &_current {
      background-color: #830051;
      color: #fff;
      border: 0;

      &:hover {
        background-color: #830051;
      }
    }
  }
}
</style>