<template>
  <div class="calendar__body">
    <div class="calendar__week-row">
      <div class="calendar__week-day">пн</div>
      <div class="calendar__week-day">вт</div>
      <div class="calendar__week-day">ср</div>
      <div class="calendar__week-day">чт</div>
      <div class="calendar__week-day">пт</div>
      <div class="calendar__week-day calendar__week-day_weekend">сб</div>
      <div class="calendar__week-day calendar__week-day_weekend">вс</div>
    </div>
    <div class="calendar__days-row">
      <CalendarDay
        @dayClick="dayClick"
        v-for="(day, ind) in Calendar"
        :key="ind"
        :day="day"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CalendarDay from "./calendarDay.vue";
export default {
  components: { CalendarDay },
  name: "CalendarBody",
  props: {
    year: Number,
    month: Number,
    currentDay: Array,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["Events", "FilteredEvents"]),
    Calendar() {
      let d = new Date(this.year, this.month);
      let lastDayMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      let days = [];
      let start = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() - this.getDay(d)
      );
      let end = new Date(
        d.getFullYear(),
        d.getMonth(),
        lastDayMonth.getDate() + (6 - this.getDay(lastDayMonth))
      );
      let today = new Date(new Date().setHours(0, 0, 0, 0));

      while (start.getTime() <= end.getTime()) {
        let date = new Date(start);
        days.push({
          date: date,
          dayNumber: date.getDate(),
          isWeekend: this.getDay(date) === 5 || this.getDay(date) === 6,
          isToday: this.isToday(date),
          events: this.hasEvents(date),
          isPassed: new Date(date).getTime() < today.getTime(),
          onlyPassedEvents: this.onlyPassed(date),
          isCurrent: this.currentDay
            ? new Date(this.currentDay[0]).getTime() ===
              new Date(date).getTime()
            : false,
          isLastMonth: start.getTime() < d.getTime(),
          isNextMonth:
            start.getTime() >
            new Date(d.getFullYear(), d.getMonth() + 1, 0).getTime(),
        });
        start.setDate(start.getDate() + 1);
      }
      return days;
    },
  },
  methods: {
    ...mapActions(["fetchEvents"]),
    getDay(date) {
      // получить номер дня недели, от 0 (пн) до 6 (вс)
      let day = date.getDay();
      if (day == 0) day = 7; // сделать воскресенье (0) последним днем
      return day - 1;
    },
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    hasEvents(dayDate) {
      return this.Events.filter(
        (el) =>
          new Date(el.date).getDate() == dayDate.getDate() &&
          new Date(el.date).getMonth() == dayDate.getMonth() &&
          new Date(el.date).getFullYear() == dayDate.getFullYear() &&
          !el.state
      );
    },
    onlyPassed(dayDate) {
      let liveEvents = this.Events.filter(
        (el) =>
          new Date(el.date).getDate() == dayDate.getDate() &&
          new Date(el.date).getMonth() == dayDate.getMonth() &&
          new Date(el.date).getFullYear() == dayDate.getFullYear() &&
          !el.state
      );
      let passedEvents = this.Events.filter(
        (el) =>
          new Date(el.date).getDate() == dayDate.getDate() &&
          new Date(el.date).getMonth() == dayDate.getMonth() &&
          new Date(el.date).getFullYear() == dayDate.getFullYear() &&
          el.state
      );
      return !!!liveEvents.length && !!passedEvents.length
    },
    async dayClick(day) {
      this.$emit("dayClick", day);
    },
  },
  async mounted() {
    await this.fetchEvents();
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  &__days-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 16px;
    row-gap: 12px;

    @media screen and (max-width: 767px) {
      column-gap: 11px;
    }
  }

  &__week-row {
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 16px;
    @media screen and (max-width: 767px) {
      column-gap: 11px;
    }
  }

  &__week-day {
    width: 35px;
    height: auto;
    text-align: center;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #656969;

    &_weekend {
      color: #d0006f;
    }

    @media screen and (max-width: 767px) {
      width: 32px;
    }
  }
}
</style>